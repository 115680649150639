import React from 'react';

const Natural = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="33.335"
    height="42.648"
    viewBox="0 0 33.335 42.648"
  >
    <defs>
      <clipPath>
        <rect width="33.335" height="42.648" fill="currentColor" />
      </clipPath>
    </defs>
    <g transform="translate(-931.583 -685.352)">
      <g transform="translate(931.583 685.352)" clipPath="url(#clip-path)">
        <path
          d="M8.644,32.407v.535q0,4.059,0,8.119a3.092,3.092,0,0,1-.046.62,1.235,1.235,0,0,1-2.424-.066,3.855,3.855,0,0,1-.028-.581q0-4.85,0-9.7a.728.728,0,0,0-.315-.659,14.95,14.95,0,0,1-1.38-22.5A27.6,27.6,0,0,1,18.522.761,38.954,38.954,0,0,1,29.738.152C30.567.22,31.395.3,32.22.4a1.2,1.2,0,0,1,1.086.944,1.255,1.255,0,0,1-.539,1.343c-.482.35-.976.688-1.427,1.075a8.332,8.332,0,0,0-2.847,5.651,33.494,33.494,0,0,0,.213,6.727,24.316,24.316,0,0,1-.153,7.5,12.007,12.007,0,0,1-6.121,8.067,14.7,14.7,0,0,1-13.395.847c-.113-.045-.227-.087-.393-.151m19.486-29c-.5.156-1,.295-1.5.472a25.974,25.974,0,0,0-11.381,8.238A28.745,28.745,0,0,0,8.7,28.835c-.061.909-.213.7.654,1.108A12.184,12.184,0,0,0,21.29,29.48a9.593,9.593,0,0,0,5.065-8.1,52.053,52.053,0,0,0-.325-7.1,25.363,25.363,0,0,1,.056-5.689A12.12,12.12,0,0,1,28.13,3.412m-5.151-.787c-.612.075-1.133.137-1.653.2A25.79,25.79,0,0,0,6.582,9.586,12.491,12.491,0,0,0,4.223,25.165a11.227,11.227,0,0,0,2,2.535C7.616,16.555,13.1,8.191,22.979,2.624"
          transform="translate(0 0)"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default Natural;
